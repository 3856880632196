import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <div className="post p2 p-responsive wrap" role="main">
            <div className="measure">
                <h1 id="section">404</h1>

                <p>Uh oh! There's nothing here...</p>
            </div>
        </div>
    </Layout>
);

export default NotFoundPage;
